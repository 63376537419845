import React from 'react'
import { Bar } from '@ant-design/charts'
import { StyledVoteContainer } from './ui'

const EventVote: React.FC = (): JSX.Element => {
  const data = [
    { event: 'Lunch @McDonalds', value: 1 },
    { event: 'Bowling', value: 3 },
    { event: 'Ping Pong', value: 4 },
  ]
  const config = {
    data,
    xField: 'value',
    yField: 'event',
    seriesField: 'event',
    legend: {
      position: 'top-left',
    },
  } as any
  return (
    <StyledVoteContainer>
      <Bar {...config} />
    </StyledVoteContainer>
  )
}

export default EventVote
