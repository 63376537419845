import React from 'react'
import { MOCK_EVENTS } from '../../event-planner/utils'
import ScheduledCard from './scheduled-card'

const ScheduledCards = () => {
  const renderEvents = () => {
    return MOCK_EVENTS.map((event) => <ScheduledCard key={event.id} event={event} />)
  }
  return <>{renderEvents()}</>
}

export default ScheduledCards
