import React from 'react'
import ReactDOM from 'react-dom'
import App from './pages/app'
import './styles/index.css'

ReactDOM.render(
  // disabling strict mode util antd supports React 17 - in progress as [May 2021]
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root')
)
