import React from 'react'
import { Pie } from '@ant-design/charts'
import { Card } from 'antd'

const PieData = () => {
  const data = [
    {
      type: 'Sports',
      value: 27,
    },
    {
      type: 'Virtual',
      value: 25,
    },
    {
      type: 'Movies',
      value: 18,
    },
    {
      type: 'Dinner',
      value: 15,
    },
    {
      type: 'Karaoke',
      value: 10,
    },
    {
      type: 'Book Club',
      value: 5,
    },
  ]
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  }
  return (
    <Card>
      <Pie {...config} height={200} />
    </Card>
  )
}

export default PieData
