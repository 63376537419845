import React from 'react'
import { Modal, Form, Input, Select } from 'antd'
import { StyledCreatorContainer, StyledUploadContainer } from './ui'

interface CreateEvenModalProps {
  isVisible: boolean
  setIsVisible: (visible: boolean) => void
}
const CreateEventModal: React.FunctionComponent<CreateEvenModalProps> = ({ isVisible, setIsVisible }) => {
  const onCancel = () => setIsVisible(false)
  const onOk = () => setIsVisible(false)
  return (
    <Modal title="Create an Event" visible={isVisible} onCancel={onCancel} onOk={onOk} okText="Send Invitation">
      <StyledCreatorContainer>
        <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="Event Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Type" name="type">
            <Select placeholder="Select event type" allowClear>
              <Select.Option value="in-person">In Person</Select.Option>
              <Select.Option value="virtual">Virtual</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Category" name="category">
            <Select placeholder="Select event category" allowClear>
              <Select.Option value="cards">Cards</Select.Option>
              <Select.Option value="sports">Sports</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Location" name="location">
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
        </Form>
        <StyledUploadContainer>
          <img alt="upload placeholder" src="https://via.placeholder.com/150" />
        </StyledUploadContainer>
      </StyledCreatorContainer>
    </Modal>
  )
}

export default CreateEventModal
