import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  background: black;
  overflow: hidden;
`

export const ErrorContainer = styled(PageContainer)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PageTitleText = styled.div`
  font-size: 18px;
  font-weight: lighter;
  color: white;
  margin-bottom: 20px;
`
