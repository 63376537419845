import ErrorBoundary from 'components/error-boundary'
import { useSupabaseContext } from 'contexts/supabase'
import React from 'react'
import { Route } from 'react-router-dom'
import useRedirect from 'utils/useRedirect'

interface Props {
  component: any
  exact?: boolean
  path: string
}

const AuthRoute: React.FC<Props> = (props) => {
  const { session, user } = useSupabaseContext()
  const { redirect } = useRedirect()
  const redirectToLogin = () => redirect('/login')
  const redirectToPendingApprovalPage = () => redirect('/pending')

  if (!session) redirectToLogin()
  if (!user?.approval) redirectToPendingApprovalPage()
  return (
    <ErrorBoundary>
      <Route {...props} />
    </ErrorBoundary>
  )
}

export default AuthRoute
