import React from 'react'
import { Card, Typography } from 'antd'
import { Column } from '@ant-design/charts'

const Breakdown: React.FC = (): JSX.Element => {
  const data = [
    {
      name: 'Remaining',
      month: 'Apr.',
      value: 200,
    },
    {
      name: 'Remaining',
      month: 'May',
      value: 100,
    },
    {
      name: 'Remaining',
      month: 'Jun.',
      value: 50,
    },
    {
      name: 'Remaining',
      month: 'Jul.',
      value: 100,
    },
    {
      name: 'Remaining',
      month: 'Aug.',
      value: 200,
    },
    {
      name: 'Spent',
      month: 'Apr.',
      value: 100,
    },
    {
      name: 'Spent',
      month: 'May',
      value: 200,
    },
    {
      name: 'Spent',
      month: 'Jun.',
      value: 250,
    },
    {
      name: 'Spent',
      month: 'Jul.',
      value: 200,
    },
    {
      name: 'Spent',
      month: 'Aug.',
      value: 100,
    },
  ]
  const config = {
    data,
    isGroup: true,
    xField: 'month',
    yField: 'value',
    seriesField: 'name',
    dodgePadding: 2,
    label: {
      position: 'middle',
      style: {
        fill: '#fff',
        fontWeight: 700,
      },
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  } as any

  return (
    <Card>
      <Typography.Title level={4}>Resource Allocation:</Typography.Title>
      <Column {...config} height={100} width={400} />
    </Card>
  )
}

export default Breakdown
