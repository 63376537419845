import React from 'react'
import { Card, Typography, Button, Divider } from 'antd'
import Transaction from './transaction'
import { MOCK_TRANSACTIONS } from '../utils'

const TransactionHistory = () => {
  const renderTransactionRow = () => {
    return MOCK_TRANSACTIONS.map((action) => <Transaction transaction={action} />)
  }
  return (
    <Card style={{ padding: '20px', marginTop: '30px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography.Title level={2}>Transaction History</Typography.Title>
        <div style={{ display: 'flex' }}>
          <Button type="primary" style={{ marginRight: '5px' }}>
            Generate Report
          </Button>
          <Button type="primary">Add Expense</Button>
        </div>
      </div>
      <Divider />
      {renderTransactionRow()}
    </Card>
  )
}

export default TransactionHistory
