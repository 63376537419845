import React from 'react'
import { Bar } from '@ant-design/charts'

const EventVote: React.FC = (): JSX.Element => {
  const data = [
    { event: 'Lunch @McDonalds', value: 1 },
    { event: 'Bowling', value: 3 },
    { event: 'Ping Pong', value: 4 },
  ]
  const config = {
    data,
    xField: 'value',
    yField: 'event',
    seriesField: 'event',
    legend: {
      position: 'top-left',
    },
  } as any
  return <Bar {...config} />
}

export default EventVote
