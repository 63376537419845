import { CalendarOutlined } from '@ant-design/icons'
import { List } from 'antd'
import React from 'react'

const Events: React.FC = (): JSX.Element => {
  const data = [
    {
      title: 'Team Dinner/Drink',
      description: 'Dinner @KTown Wings',
      date: '11/15/2021',
    },
    {
      title: 'Happy Hours',
      description: 'Drinks @Punch',
      date: '11/20/2021',
    },
    {
      title: 'Spin - Ping Pong',
      description: 'Ping Pong @Spin',
      date: '11/25/2021',
    },
  ]

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<CalendarOutlined />}
            title={<a href="/">{item.title}</a>}
            description={`${item.date} - ${item.description}`}
          />
        </List.Item>
      )}
    />
  )
}

export default Events
