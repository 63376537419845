import { Button, Input, Modal, Typography, Divider } from 'antd'
import React, { useState } from 'react'
import { UserOutlined, CloseOutlined } from '@ant-design/icons'

interface TeamInvitationProps {
  isVisible: boolean
  onCancel: () => void
  onOk: () => void
}

const TeamInvitation: React.FunctionComponent<TeamInvitationProps> = (props) => {
  const { isVisible, onCancel, onOk } = props

  const [userEmail, setUserEmail] = useState('')

  const renderInputs = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Employee Email"
          prefix={<UserOutlined />}
          style={{ width: '300px' }}
          onChange={(event) => setUserEmail(event.target.value)}
        />
        <CloseOutlined style={{ color: 'red', marginLeft: '20px' }} />
      </div>
    )
  }

  return (
    <Modal
      visible={isVisible}
      width="30%"
      onCancel={onCancel}
      onOk={onOk}
      okText="Invite"
      okButtonProps={{ disabled: !userEmail }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Title level={2}>Invite Teammates</Typography.Title>
      </div>
      <div style={{ display: 'flex', marginTop: '20px' }}>{renderInputs()}</div>
    </Modal>
  )
}

export default TeamInvitation
