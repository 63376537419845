import { Button, Result } from 'antd'
import React from 'react'
import useRedirect from 'utils/useRedirect'

const NotFound: React.FC = () => {
  const { redirect } = useRedirect()
  const handleClick = () => {
    redirect('/')
  }
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={handleClick}>
          Back Home
        </Button>
      }
    />
  )
}

export default NotFound
