import { Button, Table, Tag } from 'antd'
import React from 'react'
import { StyledContainer } from './ui'

const Stats: React.FC = (): JSX.Element => {
  const columns = [
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      render: (text: string) => <a href="/">{text}</a>,
    },
    {
      title: 'Participation Rate',
      dataIndex: 'current_rate',
      key: 'current_rate',
      render: (rate: number) => `${rate}%`,
    },
    {
      title: 'Historical Part. Rate',
      dataIndex: 'past_rate',
      key: 'past_rate',
      render: (rate: number) => `${rate}%`,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (types: string[]) => (
        <>
          {types.map((type) => {
            const color = type === 'Remote' ? 'geekblue' : 'green'
            return (
              <Tag color={color} key={type}>
                {type.toUpperCase()}
              </Tag>
            )
          })}
        </>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      event: 'Virtual Game',
      current_rate: 32,
      past_rate: 42,
      type: ['Remote'],
    },
    {
      key: '2',
      event: 'Team Dinner/Drink',
      current_rate: 42,
      past_rate: 32,
      type: ['In-Person'],
    },
    {
      key: '3',
      event: 'Engineering Conference',
      current_rate: 32,
      past_rate: 60,
      type: ['In-Person'],
    },
    {
      key: '4',
      event: 'Virtual Engineering Conference',
      current_rate: 75,
      past_rate: 90,
      type: ['Remote'],
    },
    {
      key: '5',
      event: 'Jackbox.tv',
      current_rate: 88,
      past_rate: 40,
      type: ['Remote'],
    },
  ]

  return (
    <StyledContainer>
      <Table columns={columns} dataSource={data} pagination={false} />
      <Button ghost>See More</Button>
    </StyledContainer>
  )
}

export default Stats
