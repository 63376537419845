import Navigation from 'components/navigation'
import Dashboard from 'pages/dashboard'
import EventPlanner from 'pages/event-planner'
import Feedback from 'pages/feedback'
import Login from 'pages/login'
import NotFound from 'pages/not-round'
import PendingApproval from 'pages/pending'
import Resources from 'pages/resources'
import Stats from 'pages/statics'
import Team from 'pages/team'
import UpcomingEvents from 'pages/upcoming-events'
import React from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import AuthRoute from './components/auth-route'

const Router: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route exact path="/login" render={(routeProps) => <Login type="LOGIN" {...routeProps} />} />
        <Route exact path="/sign-up" render={(routeProps) => <Login type="SIGN_UP" {...routeProps} />} />
        <Route exact path="/pending" component={PendingApproval} />
        <Navigation>
          <AuthRoute exact path="/dashboard" component={Dashboard} />
          <AuthRoute exact path="/event-planner" component={EventPlanner} />
          <AuthRoute exact path="/upcoming-events" component={UpcomingEvents} />
          <AuthRoute exact path="/team" component={Team} />
          <AuthRoute exact path="/resources" component={Resources} />
          <AuthRoute exact path="/feedbacks" component={Feedback} />
          <AuthRoute exact path="/stats" component={Stats} />
        </Navigation>
        {/** NO MATCH */}
        <Route component={NotFound} />
      </Switch>
    </HashRouter>
  )
}

export default Router
