import { createClient, Session, SupabaseClient } from '@supabase/supabase-js'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

// create once and set in global context
const supabase = createClient(
  process.env.REACT_APP_PUBLIC_SUPABASE_URL || '',
  process.env.REACT_APP_PUBLIC_SUPABASE_API_KEY || ''
)

interface SupabaseContextValue {
  session: Session | null
  supabase: SupabaseClient
  user: User.SupabaseUser | null
  handleLogin: ({ type, email, password }: { type: 'LOGIN' | 'SIGN_UP'; email: string; password: string }) => Promise<{
    hasError: boolean
    errorMsg: string
  }>
  logout: () => Promise<boolean>
}

const defaultSupabaseContextValue: SupabaseContextValue = {
  session: null,
  supabase,
  user: null,
  handleLogin: () => new Promise((resolve) => resolve({ hasError: false, errorMsg: '' })),
  logout: () => new Promise((resolve) => resolve(false)),
}

export const SupabaseContext = createContext(defaultSupabaseContextValue)

export const SupabaseContextProvider: React.FC = ({ children }) => {
  const [session, setSession] = useState<Session | null>(supabase.auth.session())
  const [user, setUser] = useState<User.SupabaseUser | null>(null)

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (_event, updatedSession) =>
      setSession(updatedSession)
    )
    return () => {
      authListener?.unsubscribe()
    }
  }, [session])

  const logout = useCallback(async (): Promise<boolean> => {
    await supabase.auth.signOut().catch(() => {
      return false
    })
    setUser(null) // clear user
    setSession(null) // clear session
    return true
  }, [])

  const handleLogin = async ({
    type,
    email,
    password,
  }: {
    type: 'LOGIN' | 'SIGN_UP'
    email: string
    password: string
  }) => {
    const { error } =
      type === 'LOGIN'
        ? await supabase.auth.signIn({ email, password })
        : await supabase.auth.signUp({ email, password })
    if (error) {
      return { hasError: true, errorMsg: error.message }
    }
    const getUser = async () => {
      const { data: users, error } = await supabase
        .from('users')
        .select('id,first_name,last_name,role_id,approval')
        .eq('id', session?.user?.id)

      if (error) {
        console.log('Error getting user', error)
      } else if (users?.length) {
        setUser(users[0])
      }
    }
    if (!user) await getUser()
    return { hasError: false, errorMsg: '' }
  }

  const value: SupabaseContextValue = {
    session,
    supabase,
    user,
    logout,
    handleLogin,
  }

  return <SupabaseContext.Provider value={value}>{children}</SupabaseContext.Provider>
}

export const useSupabaseContext = () => useContext(SupabaseContext)
export default SupabaseContext
