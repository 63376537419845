import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin-left: 25px;
  margin-right: 25px;
`

export const StyledEventsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
`

export const StyledResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid;
  padding-top: 25px;
`

export const StyledRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  margin-left: 10px;
  padding-bottom: 10px;
`

export const StyledReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledReviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 10px;
`

export const StyledReviewBody = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
`
