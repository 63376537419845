import React, { useState } from 'react'
import { Table, Tag, Typography, Avatar, Image, Button } from 'antd'
import { StyledContainer } from './ui'
import { data } from './utils'
import TeamInvitation from './component/team-invitation'
import { useNotificationContext } from 'contexts/notification'

const Team = () => {
  const { openNotification } = useNotificationContext()
  const [showModal, setShowModal] = useState(false)
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: any) => (
        <span>
          <Avatar src={<Image src="https://joeschmoe.io/api/v1/random" style={{ width: 32 }} />} /> &nbsp;
          {name}
        </span>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Interest',
      key: 'interest',
      dataIndex: 'interest',
      render: (tags: any) => (
        <>
          {tags.map((tag: any) => {
            return (
              <Tag color="geekblue" key={tag}>
                {tag.toUpperCase()}
              </Tag>
            )
          })}
        </>
      ),
    },
  ]

  const sendInvitation = () => {
    openNotification('success', 'Success', 'Invitation Sent!')
    setShowModal(false)
  }
  const cancelInvitation = () => setShowModal(false)
  const onClick = () => setShowModal(true)

  return (
    <>
      <StyledContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography.Title>Team</Typography.Title>
          <Button type="primary" onClick={onClick}>
            Invite teammate
          </Button>
        </div>
        <div style={{ height: '100%' }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </StyledContainer>
      <TeamInvitation isVisible={showModal} onCancel={cancelInvitation} onOk={sendInvitation} />
    </>
  )
}

export default Team
