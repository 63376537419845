export interface Transactions {
  name: string
  asOfDate: string
  deposit: boolean
  amount: number
}

export const MOCK_TRANSACTIONS: Transactions[] = [
  {
    name: 'Six Flags',
    asOfDate: '1/15/2021',
    deposit: false,
    amount: 500,
  },
  {
    name: 'Poker Night',
    asOfDate: '1/15/2021',
    deposit: false,
    amount: 100,
  },
  {
    name: 'Laser Tag',
    asOfDate: '1/15/2021',
    deposit: false,
    amount: 300,
  },
  {
    name: 'Knicks Game',
    asOfDate: '1/15/2021',
    deposit: false,
    amount: 300,
  },
]
