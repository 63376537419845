import styled from 'styled-components'

export const StyledEventCardContainer = styled.div`
  width: 100;
  min-height: 300;
  border: 1px white solid;
`

export const StyledDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledFilterContainer = styled.div`
  display: flex;
  padding-bottom: 50px;
`

export const StyledSelectContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
`

export const StyledModalBody = styled.div`
  display: flex;
`

export const StyledEventDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledEventCreator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  * {
    margin-bottom: 10px;
  }
`

export const StyledCreatorContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledUploadContainer = styled.div`
  display: flex;
  margin-left: 20px;
`
