import React from 'react'
import { Card, Typography, Divider, Statistic } from 'antd'

const Balance = () => {
  return (
    <Card style={{ width: '400px' }}>
      <Statistic title="Account Balance (USD)" value={5000} precision={2} />
    </Card>
  )
}

export default Balance
