import React from 'react'
import { Liquid } from '@ant-design/charts'
import { StyledRatingContainer } from './ui'

const Rating: React.FC = (): JSX.Element => {
  const config = {
    percent: 0.5,
    shape: function (x: number, y: number, width: number, height: number) {
      const r = width / 4
      const dx = x - width / 2
      const dy = y - height / 2
      return [
        ['M', dx, dy + r * 2],
        ['A', r, r, 0, 0, 1, x, dy + r],
        ['A', r, r, 0, 0, 1, dx + width, dy + r * 2],
        ['L', x, dy + height],
        ['L', dx, dy + r * 2],
        ['Z'],
      ]
    },
    outline: {
      border: 4,
      distance: 8,
    },
    wave: {
      length: 128,
    },
  } as any
  return (
    <StyledRatingContainer>
      <Liquid {...config} />
    </StyledRatingContainer>
  )
}

export default Rating
