import React, { useState } from 'react'
import { StyledDetailContainer, StyledItem, StyledEventDetails, StyledModalBody } from './ui'
import { Card, Modal } from 'antd'
import { CheckCircleTwoTone, EllipsisOutlined, PlusCircleTwoTone } from '@ant-design/icons'
import { Event } from '../utils'

interface EventCardProps {
  eventDetails: Event
  selectEvent: (event: Event) => void
  selectedEvents: Event[]
}
const EventCard: React.FunctionComponent<EventCardProps> = ({ eventDetails, selectEvent, selectedEvents }) => {
  const [selected, setSelected] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const onEventClick = () => {
    if (selectedEvents.length <= 3) {
      setSelected(!selected)
      selectEvent(eventDetails)
    }
  }

  const onClickDetails = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsModalVisible(true)
    console.log(`Get details for event ${eventDetails.id}`)
  }

  const defaultActions = [<EllipsisOutlined key="ellipsis" onClick={onClickDetails} />, <PlusCircleTwoTone />]
  const selectedActions = [
    <EllipsisOutlined key="ellipsis" onClick={onClickDetails} />,
    <CheckCircleTwoTone twoToneColor="#52c41a" />,
  ]

  const renderActions = () => (selected ? selectedActions : defaultActions)

  const renderDetails = () => (
    <StyledDetailContainer>
      <StyledItem>
        <b>Type:</b>&nbsp;{eventDetails.type}
      </StyledItem>
      <StyledItem>
        <b>Category:</b>&nbsp;{eventDetails.category}
      </StyledItem>
      <StyledItem>
        <b>Price:</b>&nbsp;{eventDetails?.price ? `$${eventDetails.price}` : 'Free'}
      </StyledItem>
      <StyledItem>
        <b>Best for group size:</b>&nbsp;{eventDetails.groupSize}+
      </StyledItem>
    </StyledDetailContainer>
  )

  const onModalClose = () => setIsModalVisible(false)

  return (
    <>
      <Card
        onClick={onEventClick}
        style={{ width: 300 }}
        hoverable
        cover={<img style={{ width: '300px', height: '200px' }} alt={eventDetails.name} src={eventDetails.imgUrl} />}
        actions={renderActions()}
      >
        <Card.Meta title={eventDetails.name} description={renderDetails()} />
      </Card>
      <Modal visible={isModalVisible} title={eventDetails.name} onCancel={onModalClose} onOk={onModalClose}>
        <StyledModalBody>
          <StyledEventDetails>
            <img
              style={{ width: '400px', height: '200px', marginBottom: '15px' }}
              alt={eventDetails.name}
              src={eventDetails.imgUrl}
            />
            <p>
              <b>Type:</b> {eventDetails.type}
            </p>
            <p>
              <b>Category:</b> {eventDetails.category}
            </p>
            <p>
              <b>Price:</b> {eventDetails.price}
            </p>
            <p>
              <b>Group size:</b> {eventDetails.groupSize}+
            </p>
            <p>
              <b>Details:</b> INSERT DETAILS OF EVENT
            </p>
          </StyledEventDetails>
        </StyledModalBody>
      </Modal>
    </>
  )
}

export default EventCard
