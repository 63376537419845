export const data = [
  {
    key: '1',
    name: 'Tim Lin',
    role: 'Data Scientist',
    phone: '(646)-111-1212',
    email: 'tim@squad-insights.com',
    location: 'New York',
    interest: ['Sports', 'Poker', 'Video Games'],
  },
  {
    key: '2',
    name: 'Connie Lai',
    role: 'Product Manager',
    phone: '(646)-344-1212',
    email: 'connie@squad-insights.com',
    location: 'New York',
    interest: ['Poker', 'Sports'],
  },
  {
    key: '3',
    name: 'Rithy Hout',
    role: 'Software Engineer',
    phone: '(646)-344-1242',
    email: 'rithy@squad-insights.com',
    location: 'New York',
    interest: ['Video Games', 'Poker'],
  },
  {
    key: '4',
    name: 'Tony Chen',
    role: 'Software Engineer',
    phone: '(646)-544-1242',
    email: 'tony@squad-insights.com',
    location: 'New York',
    interest: ['Video Games', 'Sports'],
  },
]
