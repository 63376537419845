import { NotificationContextProvider } from 'contexts/notification'
import { SupabaseContextProvider } from 'contexts/supabase'
import React from 'react'
import Router from 'router'

const App: React.FC = () => {
  return (
    <SupabaseContextProvider>
      <NotificationContextProvider>
        <Router />
      </NotificationContextProvider>
    </SupabaseContextProvider>
  )
}

export default App
