import { Card, Col, Divider, Row, Typography } from 'antd'
import React from 'react'
import Feedbacks from './components/feedbacks'
import ResourceAllocation from './components/resource-allocations'
import Stats from './components/stats'
import UpcomingEvents from './components/upcoming-events'
import EventVote from './components/vote'
import { StyledContainer } from './ui'

const Dashboard: React.FC = () => {
  return (
    <StyledContainer>
      <Row>
        <Typography.Title>Overview</Typography.Title>
      </Row>
      <Row gutter={16}>
        <Col>
          <Card title="Resources Allocation" bordered={false}>
            <ResourceAllocation />
          </Card>
        </Col>
        <Col>
          <Card title="Feedbacks" bordered={false}>
            <Feedbacks />
          </Card>
        </Col>
        <Col>
          <Card title="Voting" bordered={false}>
            <EventVote />
          </Card>
        </Col>
      </Row>
      <Divider dashed />
      <Row>
        <Col flex="auto">
          <Card title="Upcoming Events" bordered={false}>
            <UpcomingEvents />
          </Card>
        </Col>
      </Row>
      <Divider dashed />
      <Row>
        <Col flex="auto">
          <Card title="Statics" bordered={false}>
            <Stats />
          </Card>
        </Col>
      </Row>
    </StyledContainer>
  )
}

export default Dashboard
