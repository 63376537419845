export interface Event {
  name: string
  imgUrl: string
  type: string
  category: string
  price?: number
  groupSize: number
  id: number
  rating: number
}

export const MOCK_EVENTS: Event[] = [
  {
    id: 1,
    name: 'Adventure Park',
    imgUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSmAK78PzKVyEn4oksSoBIjPUC7_zaorF_Ng&usqp=CAU',
    category: 'Sport',
    price: 20.99,
    type: 'In-person',
    groupSize: 3,
    rating: 3,
  },
  {
    id: 2,
    name: 'Six Flags',
    imgUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPCRUIzfCvX5j9EE04ZkphLwf895K7ARHP7w&usqp=CAU',
    category: 'Sport',
    price: 24.99,
    type: 'In-person',
    groupSize: 4,
    rating: 5,
  },
  {
    id: 3,
    name: 'Metaverse',
    category: 'Virtual',
    imgUrl:
      'https://cdn.vox-cdn.com/thumbor/t8oJRsd7E9xJX8XxhE-ppiUPqws=/0x0:2874x1552/1200x800/filters:focal(1434x463:1892x921)/cdn.vox-cdn.com/uploads/chorus_image/image/70057958/Screen_Shot_2021_10_28_at_12.11.35_PM.0.png',
    type: 'Virtual',
    groupSize: 2,
    rating: 3.5,
  },
]
