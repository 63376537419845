import React from 'react'
import { Card, Row, Typography } from 'antd'
import { Liquid } from '@ant-design/charts'

const Star = () => {
  const config = {
    percent: 0.8,
    shape: (x: any, y: any, width: any, height: any): any => {
      const path = []
      const w = Math.min(width, height)

      for (let i = 0; i < 5; i++) {
        path.push([
          i === 0 ? 'M' : 'L',
          (Math.cos(((18 + i * 72) * Math.PI) / 180) * w) / 2 + x,
          (-Math.sin(((18 + i * 72) * Math.PI) / 180) * w) / 2 + y,
        ])
        path.push([
          'L',
          (Math.cos(((54 + i * 72) * Math.PI) / 180) * w) / 4 + x,
          (-Math.sin(((54 + i * 72) * Math.PI) / 180) * w) / 4 + y,
        ])
      }

      path.push(['Z'])
      return path
    },
    outline: {
      border: 2,
      distance: 4,
      style: {
        stroke: '#FFC100',
        strokeOpacity: 0.65,
      },
    },
    wave: {
      length: 128,
    },
    theme: {
      styleSheet: {
        brandColor: '#FAAD14',
      },
    },
  }
  return (
    <Card style={{ width: '300px' }}>
      <Liquid {...config} height={300} width={200} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography.Title level={3}>Latest Event Rating</Typography.Title>
      </div>
    </Card>
  )
}

export default Star
