import React from 'react'
import { Row, Typography } from 'antd'
import { StyledContainer } from './ui'
import Balance from './components/balance'
import TransactionHistory from './components/transaction-history'
import Breakdown from './components/breakdown'
import Pie from './components/pie-data'

const Resources = () => {
  return (
    <StyledContainer>
      <Row>
        <Typography.Title>Resources</Typography.Title>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Balance />
        <Breakdown />
        <Pie />
      </div>
      <TransactionHistory />
    </StyledContainer>
  )
}

export default Resources
