import { Button, Card, DatePicker, InputNumber, Modal, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import CreateEventCard from './components/create-event-card'
import EventCard from './components/event-card'
import FilterPanel from './components/filter-panel'
import {
  StyledContainer,
  StyledEventsContainer,
  StyledResultsContainer,
  StyledReviewBody,
  StyledReviewContainer,
  StyledReviewHeader,
  StyledRowContainer,
} from './ui'
import { Event, MOCK_EVENTS } from './utils'

const EventPlanner: React.FunctionComponent = () => {
  const [events, setEvents] = useState<Event[]>([])
  const [selectedEvents, setSelectedEvents] = useState<Event[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    setEvents(MOCK_EVENTS)
  }, [])

  const onFilterChange = (type: keyof Event, value: string) => {
    const updatedEvents = events.filter((event) => event[type] === value)
    setEvents(updatedEvents)
  }

  const selectEvent = (event: Event) => {
    if (selectedEvents.length <= 3) {
      const foundIndex = selectedEvents.findIndex((e) => e.id === event.id)
      let updatedEvents
      if (foundIndex < 0) {
        updatedEvents = selectedEvents.concat(event)
      } else {
        updatedEvents = selectedEvents.filter((e) => e.id !== event.id)
      }
      setSelectedEvents(updatedEvents)
    }
  }

  const onModalClose = () => setIsModalVisible(false)
  const onReviewClick = () => setIsModalVisible(true)

  const renderEvents = () => {
    return events.map((event) => (
      <EventCard key={event.id} eventDetails={event} selectEvent={selectEvent} selectedEvents={selectedEvents} />
    ))
  }

  const onChange = (value: any, dateString: string) => {
    console.log('Selected Time: ', value)
    console.log('Formatted Selected Time: ', dateString)
  }

  const onOk = (value: any) => {
    console.log('onOk: ', value)
  }

  const renderSelectedEvents = () => {
    return selectedEvents.map((event) => (
      <Card
        key={event.name}
        style={{ width: 180 }}
        cover={<img style={{ width: 180, height: 200 }} alt={event.name} src={event.imgUrl} />}
      >
        <Card.Meta title={event.name} />
      </Card>
    ))
  }

  return (
    <>
      <StyledContainer>
        <Row>
          <Typography.Title>Plan an Event</Typography.Title>
        </Row>
        <FilterPanel onFilterChange={onFilterChange} />
        <StyledResultsContainer>
          <StyledRowContainer>
            <p>Results ({selectedEvents.length})</p>
            <Button type="primary" onClick={onReviewClick} disabled={selectedEvents.length <= 0}>
              Review
            </Button>
          </StyledRowContainer>
          <StyledEventsContainer>
            <CreateEventCard />
            {renderEvents()}
          </StyledEventsContainer>
        </StyledResultsContainer>
      </StyledContainer>
      <Modal
        visible={isModalVisible}
        onCancel={onModalClose}
        onOk={onModalClose}
        width={'50%'}
        okText={selectedEvents.length === 1 ? 'Confirm' : 'Send for Voting'}
      >
        <StyledReviewContainer>
          <StyledReviewHeader>
            <div>
              <b>Activities Selected</b>
            </div>
            <div>
              Date for event:&nbsp;
              <DatePicker showTime onChange={onChange} onOk={onOk} size="small" />
            </div>
            <div>
              Timer (days):&nbsp;
              <InputNumber max={7} defaultValue={3} size="small" min={0} />
            </div>
          </StyledReviewHeader>
          <StyledReviewBody>{renderSelectedEvents()}</StyledReviewBody>
        </StyledReviewContainer>
      </Modal>
    </>
  )
}

export default EventPlanner
