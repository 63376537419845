import React from 'react'
import { StyledContainer } from './ui'
import { Typography, Row, Divider } from 'antd'
import Star from './components/star'
import Surveys from './components/surveys'
import RatingChart from './components/rating-chart'
import FeedbackChart from './components/feedback'

const Feedback = () => {
  return (
    <StyledContainer>
      <Row>
        <Typography.Title>Feedback</Typography.Title>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Star />
        <RatingChart />
        <FeedbackChart />
      </div>

      <Divider />
      <Row>
        <Typography.Title>Survey</Typography.Title>
      </Row>
      <Surveys />
    </StyledContainer>
  )
}

export default Feedback
