import {
  BarChartOutlined,
  CalendarOutlined,
  CommentOutlined,
  CreditCardOutlined,
  DesktopOutlined,
  HeartTwoTone,
  LogoutOutlined,
  RocketOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Dropdown, Layout, Menu } from 'antd'
import { Footer, Header } from 'antd/lib/layout/layout'
import { useNotificationContext } from 'contexts/notification'
import { useSupabaseContext } from 'contexts/supabase'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useRedirect from 'utils/useRedirect'
import {
  LeftContainer,
  RightContainer,
  StyledContainer,
  StyledContentContainer,
  StyledContentLayout,
  StyledLogoContainer,
  StyledLogoText,
  StyledSubMenuText,
  StyledText,
  StyledUserText,
} from './ui'

interface Props {
  children: React.ReactNode
}

const Navigation: React.FC<Props> = ({ children }) => {
  const { redirect, history } = useRedirect()
  const currentNavSelection = history.location.pathname.split('/')[1]

  const { logout } = useSupabaseContext()
  const { openNotification } = useNotificationContext()

  const [collapsed, setCollapsed] = useState<boolean>(false)

  const handleLogout = async () => {
    const isSuccessful = await logout()
    if (isSuccessful) {
      redirect('/login')
    } else {
      openNotification('error', 'Failed', 'Logout Failed!')
    }
  }

  const getUserMenu = (): JSX.Element => (
    <Menu style={{ minWidth: '160px' }}>
      <Menu.Item key="profile">
        <UserOutlined />
        <StyledUserText>Profile</StyledUserText>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined />
        <StyledUserText>Logout</StyledUserText>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <StyledLogoContainer>
          <LeftContainer>
            <RocketOutlined />
            <StyledLogoText>Squad Insights</StyledLogoText>
          </LeftContainer>
          <RightContainer>
            <Dropdown overlay={getUserMenu()}>
              <div>
                <UserOutlined />
                <StyledUserText>Rithy Huot</StyledUserText>
              </div>
            </Dropdown>
          </RightContainer>
        </StyledLogoContainer>
      </Header>
      <Layout>
        <Layout.Sider
          style={{ height: '100vh', overflow: 'auto', position: 'fixed', left: 0, marginTop: '64px' }}
          collapsible
          onCollapse={setCollapsed}
        >
          <StyledContainer>
            <Menu
              style={{ height: '100vh' }}
              selectedKeys={[currentNavSelection]}
              defaultSelectedKeys={['dashboard']}
              mode="inline"
            >
              <Menu.Item key="dashboard" icon={<DesktopOutlined />}>
                <Link to="/dashboard">
                  <StyledText>Overview</StyledText>
                </Link>
              </Menu.Item>
              <Menu.SubMenu style={{ fontSize: '18px' }} key="event" icon={<CalendarOutlined />} title="Events">
                <Menu.Item key="event-planner">
                  <Link to="/event-planner">
                    <StyledSubMenuText>Plan an Event</StyledSubMenuText>
                  </Link>
                </Menu.Item>
                <Menu.Item key="event2">
                  <Link to="/upcoming-events">
                    <StyledSubMenuText>Upcoming Events</StyledSubMenuText>
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key="team" icon={<TeamOutlined />}>
                <Link to="/team">
                  <StyledText>Team</StyledText>
                </Link>
              </Menu.Item>
              <Menu.Item key="resources" icon={<CreditCardOutlined />}>
                <Link to="/resources">
                  <StyledText>Resources</StyledText>
                </Link>
              </Menu.Item>
              <Menu.Item key="feedbacks" icon={<CommentOutlined />}>
                <Link to="/feedbacks">
                  <StyledText>Feedbacks</StyledText>
                </Link>
              </Menu.Item>
              <Menu.Item key="stats" icon={<BarChartOutlined />}>
                <Link to="/stats">
                  <StyledText>Stats</StyledText>
                </Link>
              </Menu.Item>
            </Menu>
          </StyledContainer>
        </Layout.Sider>
        <StyledContentLayout collapsed={collapsed}>
          <Layout.Content style={{ overflow: 'initial' }}>
            <StyledContentContainer>{children}</StyledContentContainer>
          </Layout.Content>
          <Footer style={{ textAlign: 'center' }}>
            <HeartTwoTone twoToneColor="#eb2f96" />
            {` Squad Insights @${new Date().getFullYear()}`}
          </Footer>
        </StyledContentLayout>
      </Layout>
    </Layout>
  )
}

export default Navigation
