import React from 'react'
import { Row, Typography, Divider } from 'antd'
import { StyledContainer } from './ui'
import VotingCard from './components/voting-card'
import ScheduledCards from './components/scheduled-cards'

const UpcomingEvents = () => {
  return (
    <StyledContainer>
      <Row>
        <Typography.Title>Upcoming events</Typography.Title>
      </Row>
      <Row>
        <Typography.Text>In Voting</Typography.Text>
      </Row>
      <VotingCard />
      <Divider />
      <Row>
        <Typography.Text>Upcoming</Typography.Text>
      </Row>
      <ScheduledCards />
    </StyledContainer>
  )
}

export default UpcomingEvents
