import React, { useState } from 'react'
import { Card, Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { StyledEventCreator } from './ui'
import CreateEventForm from './create-event-modal'

const CreateEventCard: React.FunctionComponent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const onClick = () => setIsModalVisible(true)
  const renderCreateEvent = () => {
    return (
      <StyledEventCreator>
        <PlusCircleOutlined />
        <Button type="primary" onClick={onClick}>
          Create Your Own Event
        </Button>
      </StyledEventCreator>
    )
  }

  return (
    <>
      <Card style={{ width: 300 }} cover={renderCreateEvent()} />
      <CreateEventForm isVisible={isModalVisible} setIsVisible={setIsModalVisible} />
    </>
  )
}

export default CreateEventCard
