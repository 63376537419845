import React from 'react'
import { MOCK_EVENTS } from '../utils'
import Survey from './survey'

const Surveys = () => {
  const renderSurvey = () => {
    return MOCK_EVENTS.map((event) => <Survey event={event} />)
  }
  return <div style={{ height: '40vh', overflowY: 'scroll' }}>{renderSurvey()}</div>
}

export default Surveys
