import React from 'react'
import { Card, Typography, Rate } from 'antd'
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { Event } from '../utils'

interface SurveyProps {
  event: Event
}
const Survey: React.FunctionComponent<SurveyProps> = ({ event }) => {
  return (
    <Card style={{ width: '100%', marginTop: '15px' }}>
      <Typography.Title level={4}>{event.name}</Typography.Title>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src={event.imgUrl} alt={event.name} style={{ width: '300px', height: '150px' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>
            <CalendarOutlined /> 03/15/2022{' '}
          </span>
          <span>
            <ClockCircleOutlined /> 6:00PM EST
          </span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>
            <b>Number of Attendees: </b>10
          </span>
          <span>
            <b>Surveys submitted: </b>8
          </span>
          <span>
            <b>Surveys incomplete: </b>2
          </span>
        </div>
        <div>
          <Rate allowHalf defaultValue={event.rating} disabled />
        </div>
      </div>
    </Card>
  )
}

export default Survey
