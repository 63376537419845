import { Card, Col, Divider, Row, Typography } from 'antd'
import React from 'react'
import Stats from './components/stats'
import { StyledContainer } from './ui'

const Statics = (): JSX.Element => {
  return (
    <StyledContainer>
      <Row>
        <Typography.Title>Statics</Typography.Title>
      </Row>
      <Row gutter={16}>
        <Col>
          <Card title="Participation Leader" bordered={false}></Card>
        </Col>
        <Col>
          <Card title="Participation Lagger" bordered={false}></Card>
        </Col>
        <Col>
          <Card title="Category vs Participation" bordered={false}></Card>
        </Col>
      </Row>
      <Divider dashed />
      <Row>
        <Col flex="auto">
          <Stats />
        </Col>
      </Row>
    </StyledContainer>
  )
}

export default Statics
