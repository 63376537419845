import styled from 'styled-components'
import { DEVICE_SIZE } from 'utils/breakpoints'

export const StyledPieContainer = styled.div`
  width: 200px;
  height: 200px;

  @media ${DEVICE_SIZE.desktop} {
    width: 250px;
    height: 250px;
  }
`
