import Layout from 'antd/lib/layout'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: auto;
  max-width: 200px;
`
export const StyledContentContainer = styled.div`
  margin-top: 75px;
`

export const StyledText = styled.span`
  font-size: 18px;
`

export const StyledSubMenuText = styled.span`
  font-size: 14px;
`

export const StyledLogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
`

export const StyledLogoText = styled.span`
  margin-left: 15px;
`

export const LeftContainer = styled.div``

export const RightContainer = styled.div``

export const StyledUserText = styled(StyledText)`
  margin-left: 10px;
`

interface StyledContentLayoutProps {
  collapsed: boolean
}

export const StyledContentLayout = styled(Layout)<StyledContentLayoutProps>`
  margin-left: ${({ collapsed }) => (collapsed ? '75px' : '200px')};
  transition: margin 150ms ease-in-out;
`
