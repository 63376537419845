import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'
import React, { createContext, useCallback, useContext } from 'react'

interface NotificationContextValue {
  openNotification: (type: AppNotification.Type, title: string, message: string) => void
}

const defaultNotificationContextValue: NotificationContextValue = {
  openNotification: () => {},
}

export const NotificationContext = createContext(defaultNotificationContextValue)

export const NotificationContextProvider: React.FC = ({ children }) => {
  const [api, contextHolder] = notification.useNotification()

  const openNotification = useCallback(
    (type: AppNotification.Type, title: string, message: string) => {
      const params: ArgsProps = {
        message: title,
        description: message,
        placement: 'bottomRight',
      }
      switch (type) {
        case 'success':
          api.success(params)
          break
        case 'error':
          api.error(params)
          break
        case 'info':
          api.info(params)
          break
        case 'warning':
          api.warning(params)
          break
        case 'open':
          api.open(params)
          break
        default:
          api.info(params)
      }
    },
    [api]
  )

  const value: NotificationContextValue = {
    openNotification,
  }

  return (
    <NotificationContext.Provider value={value}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotificationContext = () => useContext(NotificationContext)
export default NotificationContext
