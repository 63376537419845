import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Divider, Form, Input } from 'antd'
import { useNotificationContext } from 'contexts/notification'
import { useSupabaseContext } from 'contexts/supabase'
import React, { useState } from 'react'
import useRedirect from 'utils/useRedirect'
import { layout, tailLayout } from './constants'
import { Background, StyledContainer } from './ui'

interface Props {
  type: 'LOGIN' | 'SIGN_UP'
}

const Login = ({ type }: Props) => {
  const { redirect } = useRedirect()
  const { handleLogin } = useSupabaseContext()
  const { openNotification } = useNotificationContext()
  const [hasError, setHasError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('Incorrect Username/Password')

  const onFinish = async ({ email, password }: User.User) => {
    const { hasError, errorMsg } = await handleLogin({ email, password, type })
    if (!hasError) {
      switch (type) {
        case 'LOGIN':
          openNotification('success', 'Success', 'Login successfully!')
          redirect('/dashboard')
          break
        case 'SIGN_UP':
          openNotification('success', 'Success', 'Sign up successfully!')
          redirect('/pending')
          break
      }
    } else {
      openNotification('error', 'Failed', 'Login Failed!')
      setHasError(true)
      setErrorMsg(errorMsg)
    }
  }

  const onClose = () => setHasError(false)
  const renderError = () =>
    hasError && (
      <>
        <Alert message={errorMsg} closable onClose={onClose} type="error" />
        <Divider />
      </>
    )
  const title = type === 'LOGIN' ? 'Login' : 'Sign Up'
  return (
    <Background>
      <StyledContainer>
        <Card title={title} bordered={false} style={{ width: 600 }}>
          {renderError()}
          <Form {...layout} name="basic" onFinish={onFinish}>
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input placeholder="Email" prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                {title}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </StyledContainer>
    </Background>
  )
}

export default Login
