import { Pie } from '@ant-design/charts'
import React from 'react'
import { StyledPieContainer } from './ui'

const BreakdownPieGraph: React.FC = (): JSX.Element => {
  const data = [
    {
      type: 'Remaining',
      value: 200,
    },
    {
      type: 'Spent',
      value: 100,
    },
  ]
  const cfg = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    legend: false,
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        fill: '#fff',
        fontSize: 18,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
    ],
  } as any
  return (
    <StyledPieContainer>
      <Pie {...cfg} />
    </StyledPieContainer>
  )
}

export default BreakdownPieGraph
