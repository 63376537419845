import React from 'react'

import Rating from './components/rating'
import { StyledContainer } from './ui'

const Feedbacks: React.FC = (): JSX.Element => {
  return (
    <StyledContainer>
      <Rating />
    </StyledContainer>
  )
}

export default Feedbacks
