import BackgroundImage from 'assets/background.jpg'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`

export const Background = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.6)), url('${BackgroundImage}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
`
