import React, { useState } from 'react'
import { Card, Row, DatePicker, TimePicker, Button, Modal } from 'antd'
import { EditOutlined, CheckOutlined } from '@ant-design/icons'
import { Event } from '../../event-planner/utils'
import moment from 'moment'

interface ScheduledCardProps {
  event: Event
}
const ScheduledCard: React.FunctionComponent<ScheduledCardProps> = ({ event }) => {
  const [isEditable, setIsEditable] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const cancelEvent = () => setIsModalVisible(true)
  const confirm = () => {
    setIsModalVisible(false)
  }

  const clickEdit = () => setIsEditable(true)
  const clickSave = () => setIsEditable(false)
  const renderAction = () => {
    return isEditable ? <CheckOutlined onClick={clickSave} /> : <EditOutlined onClick={clickEdit} />
  }

  const renderEvent = () => {
    return (
      <>
        <Modal visible={isModalVisible} okText="Confirm" onCancel={cancelEvent} onOk={confirm}>
          <p>Are you sure you want to cancel this event?</p>
        </Modal>
        <Row style={{ marginTop: '10px' }} key={event.id}>
          <Card style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1>{event.name}</h1>
              {renderAction()}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <img src={event.imgUrl} alt={event.name} style={{ width: '300px', height: '150px' }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  <b>Type: </b>
                  {event.type}
                </span>
                <span>
                  <b>Category: </b>
                  {event.category}
                </span>
                <span>
                  <b>Price: </b>${event.price}
                </span>
                <span>
                  <b>Group Size: </b>
                  {event.groupSize}+
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  <b>Confirmed Attendees: </b>10
                </span>
                <span>
                  <b>Unconfirmed Attendees: </b>2
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <DatePicker defaultValue={moment('2015-06-06', 'YYYY-MM-DD')} disabled={!isEditable} />
                  <TimePicker defaultValue={moment('12:08:23', 'HH:mm:ss')} disabled={!isEditable} />
                </div>
                <Button onClick={cancelEvent} type="primary" danger>
                  Cancel Event
                </Button>
              </div>
            </div>
          </Card>
        </Row>
      </>
    )
  }
  return <>{renderEvent()}</>
}

export default ScheduledCard
