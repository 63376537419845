import React from 'react'

import BreakdownPieGraph from './components/breakdown'
import { StyledContainer } from './ui'

const ResourceAllocation: React.FC = (): JSX.Element => {
  return (
    <StyledContainer>
      <BreakdownPieGraph />
    </StyledContainer>
  )
}

export default ResourceAllocation
