import React from 'react'
import { Column } from '@ant-design/charts'
import { InfoCircleOutlined } from '@ant-design/icons'
import { MOCK_EVENTS } from '../utils'
import { Card, Typography, Tooltip } from 'antd'

const RatingChart = () => {
  const data = MOCK_EVENTS.map((event) => ({
    type: event.name,
    value: event.rating,
  }))
  const config = {
    width: 500,
    height: 300,
    autoFit: false,
    appendPadding: 0,
    data,
    yField: 'value',
    xField: 'type',
    label: false,
  }
  return (
    <Card>
      <Column {...config} />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Typography.Title level={3}>Past Event Ratings</Typography.Title>
        &nbsp;
        <Tooltip placement="top" title="Average Star Rating">
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    </Card>
  )
}

export default RatingChart
