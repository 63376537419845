import { Card } from 'antd'
import { useSupabaseContext } from 'contexts/supabase'
import React from 'react'
import useRedirect from 'utils/useRedirect'
import { Background, StyledContainer } from './ui'

const PendingApproval = () => {
  const { redirect } = useRedirect()
  const { user } = useSupabaseContext()
  if (user) redirect('/dashboard')
  return (
    <Background>
      <StyledContainer>
        <Card title="Pending Approval" bordered={false} style={{ width: 600 }}>
          Your account is pending approval. Please contact support for assistant. Thank you.
        </Card>
      </StyledContainer>
    </Background>
  )
}

export default PendingApproval
