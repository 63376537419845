import React, { useState } from 'react'
import { Row, Card, DatePicker, TimePicker, Modal, Button, Typography, Statistic } from 'antd'
import moment from 'moment'
import EventVote from './event-vote'
const { Countdown } = Statistic

const VotingCard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const cancelEvent = () => setIsModalVisible(true)
  const confirm = () => {
    setIsModalVisible(false)
  }
  const onCancelModal = () => setIsModalVisible(false)
  const deadline = Date.now() + 500 * 60 * 60 * 24 * 2 + 1000 * 3
  return (
    <>
      <Modal visible={isModalVisible} okText="Confirm" onCancel={onCancelModal} onOk={confirm}>
        <p>Are you sure you want to cancel this event?</p>
      </Modal>
      <Row style={{ marginTop: '10px' }}>
        <Card style={{ width: '100% ' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginRight: '20px',
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            Voting Ends: &nbsp;&nbsp;
            <Countdown value={deadline} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ height: '125px', width: '80%' }}>
              <EventVote />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DatePicker defaultValue={moment('2015-06-06', 'YYYY-MM-DD')} disabled />
                <TimePicker defaultValue={moment('12:08:23', 'HH:mm:ss')} disabled />
              </div>
              <Button onClick={cancelEvent} type="primary" danger>
                Cancel Event
              </Button>
            </div>
          </div>
        </Card>
      </Row>
    </>
  )
}

export default VotingCard
