import React from 'react'
import { Column } from '@ant-design/charts'
import { Card, Typography } from 'antd'

const Feedback: React.FC = () => {
  const data = [
    {
      name: 'Like',
      month: 'Apr.',
      value: 5,
    },
    {
      name: 'Like',
      month: 'May',
      value: 3,
    },
    {
      name: 'Like',
      month: 'Jun.',
      value: 5,
    },
    {
      name: 'Like',
      month: 'Jul.',
      value: 6,
    },
    {
      name: 'Like',
      month: 'Aug.',
      value: 5,
    },
    {
      name: 'Dislike',
      month: 'Apr.',
      value: 5,
    },
    {
      name: 'Dislike',
      month: 'May',
      value: 7,
    },
    {
      name: 'Dislike',
      month: 'Jun.',
      value: 5,
    },
    {
      name: 'Dislike',
      month: 'Jul.',
      value: 4,
    },
    {
      name: 'Dislike',
      month: 'Aug.',
      value: 5,
    },
  ]

  const config = {
    data,
    isStack: true,
    xField: 'month',
    yField: 'value',
    seriesField: 'name',
    label: {
      position: 'middle', // 'top', 'bottom', 'middle'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
    connectedArea: {
      style: (oldStyle: any) => {
        return {
          fill: 'rgba(0,0,0,0.25)',
          stroke: oldStyle.fill,
          lineWidth: 0.5,
        }
      },
    },
  } as any

  return (
    <Card>
      <Column {...config} height={300} width={200} />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Typography.Title level={4}>Feedback Ratings</Typography.Title>
      </div>
    </Card>
  )
}

export default Feedback
