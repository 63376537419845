import React from 'react'

import Events from './components/events'

const UpcomingEvents: React.FC = (): JSX.Element => {
  return (
    <div>
      <Events />
    </div>
  )
}

export default UpcomingEvents
