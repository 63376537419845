import React from 'react'
import { StyledFilterContainer, StyledSelectContainer } from './ui'
import { Event } from '../utils'
import { Input, Select, InputNumber, Button } from 'antd'
const { Search } = Input
const { Option } = Select

interface FilterPanelProps {
  onFilterChange: (key: keyof Event, value: string) => void
}

const FilterPanel: React.FunctionComponent<FilterPanelProps> = ({ onFilterChange }) => {
  const onSearch = () => () => {}
  const onChange = () => {}
  const onSelect = (type: keyof Event) => (e: any) => onFilterChange(type, e)
  return (
    <StyledFilterContainer>
      <Search placeholder="Search" onSearch={onSearch} style={{ width: 300 }} />
      <StyledSelectContainer>
        <Select placeholder="Type" onChange={onSelect('type')}>
          <Option value="In-person">In Person</Option>
          <Option value="Virtual">Remote</Option>
        </Select>
      </StyledSelectContainer>
      <StyledSelectContainer>
        <Select placeholder="Category" onChange={onSelect('category')}>
          <Option value="Sport">Sports</Option>
          <Option value="Virtual">Virtual</Option>
        </Select>
      </StyledSelectContainer>
      <StyledSelectContainer>
        <Select placeholder="Price">
          <Option value="Free">Free</Option>
          <Option value="$">$</Option>
          <Option value="$$">$$</Option>
          <Option value="$$$">$$$</Option>
        </Select>
      </StyledSelectContainer>
      <InputNumber min={1} max={10} defaultValue={3} onChange={onChange} />
    </StyledFilterContainer>
  )
}

export default FilterPanel
