import { Button } from 'antd'
import React from 'react'

import { ErrorContainer, PageTitleText } from './ui'

class ErrorBoundary extends React.PureComponent {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any, info: any) {
    // log to monitor tool
    console.error('Error Boundary caught an error', error, info)
  }

  resetAfterError() {
    this.setState({ hasError: false })
  }

  render() {
    const { hasError } = this.state

    // fallback error component for white screening
    if (hasError)
      return (
        <ErrorContainer>
          <PageTitleText>Something went wrong. Our engineers have been notified.</PageTitleText>
          <Button key="reset-page" onClick={this.resetAfterError}>
            Reset Page
          </Button>
          ,
        </ErrorContainer>
      )

    return this.props.children
  }
}

export default ErrorBoundary
