import React from 'react'
import { Avatar, Divider } from 'antd'
import { UserOutlined, ProfileOutlined } from '@ant-design/icons'
import { Transactions } from '../utils'

interface TransactionProp {
  transaction: Transactions
}

const Transaction: React.FunctionComponent<TransactionProp> = ({ transaction }) => {
  const renderAmount = () => {
    const sign = transaction.deposit ? '+' : '-'
    const color = transaction.deposit ? '#005b8f' : 'red'
    return (
      <div style={{ color: color, fontSize: '20px' }}>
        {sign} ${transaction.amount}
      </div>
    )
  }
  return (
    <div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Avatar size={64} icon={<UserOutlined />} style={{ marginRight: '20px' }} />
          <div>
            <h1>{transaction.name}</h1>
            <p>January 2021, at 10:45PM</p>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {renderAmount()} &nbsp;
          <ProfileOutlined style={{ fontSize: '20px' }} />
        </div>
      </div>
      <Divider />
    </div>
  )
}

export default Transaction
